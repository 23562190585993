window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

function toggleNav() {
    var nav = document.getElementById('mobileNav');
    if (nav.style.width === '250px') {
        nav.style.width = '0'; // Hide the navigation
    } else {
        nav.style.width = '250px'; // Show the navigation
    }
}